import React from 'react';
import { FaFacebook, FaViber, FaWhatsapp, FaEnvelope } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-links">
          <a href="/" className="footer-link">गृहपृष्ठ</a>
          <a href="/about" className="footer-link">मेरो बारेमा</a>
          <a href="/services" className="footer-link">सेवाहरू</a>
          <a href="/contact" className="footer-link">सम्पर्क</a>
        </div>
        <div className="footer-social-icons">
          <a href="https://www.facebook.com/atrimuni.np" target="_blank" rel="noopener noreferrer" className="footer-icon-link"><FaFacebook /></a>
          <a href="viber://chat?number=+9779846111199" target="_blank" rel="noopener noreferrer" className="footer-icon-link"><FaViber /></a>
          <a href="https://api.whatsapp.com/send?phone=9779846111199" target="_blank" rel="noopener noreferrer" className="footer-icon-link"><FaWhatsapp /></a>
          <a href="mailto:prasad321bishnu@gmail.com" target="_blank" rel="noopener noreferrer" className="footer-icon-link"><FaEnvelope /></a>
        </div>
      </div>
      <p className="footer-text">&copy; {new Date().getFullYear()} Atri Muni. All rights reserved.</p>
    </footer>
  );
};

export default Footer;

import React from "react";
import "./About.css";
import myImage from "../assets/atri-muni.jpg";

const About = () => {
  return (
    <div className="about-container mt-10">
      {/* <h1 className="about-heading">About</h1> */}
      <div className="about-content">
        <div className="about-description">
          <h2 className="about-subheading">
            हिन्दू दार्शनिक, पण्डित र ज्योतिषी अत्री मुनि
          </h2>
          <p>
            A highly educated and experienced Hindu religious services provider
            in and around Pokhara and Kathmandu Valley with effective
            communication skills and services in 4 languages. Providing Hindu
            religious services in Nepal for over a decade.
          </p>
          <div className="about-specialties">
            <p>विशेषताहरू:</p>
            <ul>
              <li>हिन्दू विवाह समारोहहरू (Hindu Wedding Ceremonies)</li>
              <li>ज्योतिष र करियर सुझाव (Astrology and Career Suggestions)</li>
              <li>
                मन्दिर र गृह वास्तु सेवाहरू (Temple and Griha Vastu Services)
              </li>
              <li>यज्ञ र याग (Yagna and Yaga)</li>
              <li>होम र जपम (Homa and Japam)</li>
              <li>पूजा र व्रत (Pooja and Vratam)</li>
              <li>
                सीमान्थ, नामकरण, उपनयन सेवाहरू (Seemantha, Naamakarana,
                Upanayana services)
              </li>
              <li>
                हिन्दू मृत्यु संस्कार सेवाहरू (पक्षम, तिथि, आदि) (Hindu funeral
                ritual services (paksham, tidhi, etc))
              </li>
            </ul>
          </div>
          <h3 className="education-heading">शिक्षा</h3>
          <div className="education-details">
            <div>
              <h4>बिन्ध्यबासिनी वैदिक आश्रम</h4>
              <p>संस्कृत अध्ययन</p>
              <p>
                Prepares me for a highly qualified specialist in the field of
                Vedanta and Dvaita philosophies and includes study of RIG VEDA
                and YAJUR VEDA
              </p>
              <ul>
                <li>
                  Sanskrit Prose, Poetry, Drama, and history of classical
                  Sanskrit literature
                </li>
                <li>Arthashastra</li>
                <li>Upanishads</li>
                <li>Vedic Hymns</li>
                <li>Nyaya (Logic)</li>
                <li>Smriti (Epic texts)</li>
                <li>Bhagavad Gita</li>
                <li>History of Vedic literature</li>
                <li>Vyaakarana (Grammar)</li>
                <li>Linguistics & Alamkara (Rhetoric)</li>
                <li>Advanced Vyaakarana (Grammar)</li>
              </ul>
            </div>
            <div>
              <h4>पृथ्वी नारायण क्याम्पस, पोखरा, नेपाल</h4>
              <ul>
                <li>
                  शिक्षा विज्ञानमा स्नातक उपाधि
                </li>
                <li>
                  शिक्षा विज्ञानमा स्नातकोत्तर उपाधि
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="about-image">
          <img src={myImage} alt="Profile" className="profile-image" />
        </div>
      </div>
    </div>
  );
};

export default About;

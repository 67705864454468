
import React from 'react';
import { FaFacebook, FaViber, FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './Home.css'; // Import the CSS file for Home component
import homeImage from "../assets/home.jpg";

const Home = () => {
  return (
    <div className="home-container">   
      <div className="home-content">
        <h2>ॐ <br/> अत्री मुनी (वैदिक गुरु)</h2>
        <img src={homeImage} alt="Atri Muni" className="my-photo" />
        <p className="highlighted-text">हिन्दू दार्शनिक र ज्योतिषी अत्री मुनि, उच्च शिक्षा र अनुभवी हिन्दू धार्मिक सेवा प्रदायी पण्डित।</p>
        <p>
          वैदिक विधिपूर्वक सबै प्रकारका कर्मकाण्ड, कथा, प्रवचन तथा ज्योतिष सेवाको लागि सम्पर्क गर्नुहोस्।
        </p>
        <Link to="/about" className="more-info-link">More Information</Link>
        <div className="social-links">
          <a href="https://www.facebook.com/atrimuni.np" target="_blank" rel="noopener noreferrer">
            <FaFacebook className="social-icon" />
          </a>
          <a href="viber://chat?number=+9779846111199" target="_blank" rel="noopener noreferrer">
            <FaViber className="social-icon" />
          </a>
          <a href="https://api.whatsapp.com/send?phone=9779846111199" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp className="social-icon" />
          </a>
          {/* Add more social links */}
        </div>
        
      </div>
    </div>
  );
};

export default Home;


import React from 'react';
import './Contact.css'; // Import the CSS file for Contact component

const Contact = () => {
  return (
    <div className="contact-container">
      {/* <h1 className="contact-heading">Contact</h1> */}
      <div className="contact-content">
        <div className="contact-info">
          <h2 className="contact-subheading">सम्पर्क गर्नुहोस्</h2>
          <p>
          यदि तपाईंसँग कुनै प्रश्न वा जानकारी छ भने, कृपया तलका विवरणहरू प्रयोग गरेर मलाई सम्पर्क गर्नुहोस्:
          </p>
          <ul className="contact-details">
            <li><strong>ईमेल:</strong> prasad321bishnu@gmail.com</li>
            <li><strong>फोन नम्बर:</strong> +977 9846111199 (+९७७ ९८४६११११९९)</li>
            <li><strong>ठेगना:</strong> बिरौटा - १७, पोखरा </li>
          </ul>
        </div>
        <div className="contact-form">
          <h2 className="contact-subheading">सन्देश पठाउनुहोस्</h2>
          <form>
            <div className="form-group">
              <label htmlFor="name">प्रयोगकर्ता नाम</label>
              <input type="text" id="name" name="name" required />
            </div>
            <div className="form-group">
              <label htmlFor="email">ईमेल</label>
              <input type="email" id="email" name="email" required />
            </div>
            <div className="form-group">
              <label htmlFor="message">सन्देश छोड्नुहोस्</label>
              <textarea id="message" name="message" required></textarea>
            </div>
            <button type="submit" className="submit-button">पेश गर्नुहोस्</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;


import React from "react";

import Galary1 from "../assets/Galary1.jpg";
import Galary2 from "../assets/Galary2.jpg";
import Galary3 from "../assets/Galary3.jpg";
import Galary4 from "../assets/Galary4.jpg";
import Galary5 from "../assets/Galary5.jpg";
import Galary6 from "../assets/Galary6.jpg";
import Galary7 from "../assets/Galary7.jpg";
import Galary8 from "../assets/Galary8.jpg";
import Galary9 from "../assets/Galary9.jpg";
import Galary10 from "../assets/Galary10.jpg";
import Galary11 from "../assets/Galary11.jpeg";
import Galary12 from "../assets/Galary12.jpeg";

import "./Gallery.css";

const Gallery = () => {
  return (
    <div className="gallery-container">
      <div>
        <div className="gallery-row">
          <a className="gallery-item" href={Galary1} data-lg-size="1600-1200">
            <img src={Galary1} alt="Atri Muni" className="img-responsive" />
          </a>
          <a className="gallery-item" href={Galary2} data-lg-size="1600-1200">
            <img src={Galary2} alt="Atri Muni" className="img-responsive" />
          </a>
          <a className="gallery-item" href={Galary3} data-lg-size="1600-1200">
            <img src={Galary3} alt="Atri Muni" className="img-responsive" />
          </a>
        </div>
        <div className="gallery-row">
          <a className="gallery-item" href={Galary4} data-lg-size="1600-1200">
            <img src={Galary4} alt="Atri Muni" className="img-responsive" />
          </a>
          <a className="gallery-item" href={Galary5} data-lg-size="1600-1200">
            <img src={Galary5} alt="Atri Muni" className="img-responsive" />
          </a>
          <a className="gallery-item" href={Galary6} data-lg-size="1600-1200">
            <img src={Galary6} alt="Atri Muni" className="img-responsive" />
          </a>
        </div>
        <div className="gallery-row">
          <a className="gallery-item" href={Galary7} data-lg-size="1600-1200">
            <img src={Galary7} alt="Atri Muni" className="img-responsive" />
          </a>
          <a className="gallery-item" href={Galary8} data-lg-size="1600-1200">
            <img src={Galary8} alt="Atri Muni" className="img-responsive" />
          </a>
          <a className="gallery-item" href={Galary9} data-lg-size="1600-1200">
            <img src={Galary9} alt="Atri Muni" className="img-responsive" />
          </a>
        </div>
        <div className="gallery-row">
          <a className="gallery-item" href={Galary10} data-lg-size="1600-1200">
            <img src={Galary10} alt="Atri Muni" className="img-responsive" />
          </a>
          <a className="gallery-item" href={Galary11} data-lg-size="1600-1200">
            <img src={Galary11} alt="Atri Muni" className="img-responsive" />
          </a>
          <a className="gallery-item" href={Galary12} data-lg-size="1600-1200">
            <img src={Galary12} alt="Atri Muni" className="img-responsive" />
          </a>
        </div>
        {/* Add more gallery items as needed */}
      </div>
    </div>
  );
};

export default Gallery;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import "./Navigation.css"; // Import the CSS file for Navigation component

const Navigation = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo">
          <Link to="/">अत्री मुनी (Atri Muni)</Link>
        </div>
        <div
          className={`navbar-mobile-menu ${isMobileMenuOpen ? "open" : ""}`}
          onClick={handleMobileMenuToggle}
        >
          <FaBars className="menu-icon" />
        </div>
        <ul className={`navbar-menu ${isMobileMenuOpen ? "open" : ""}`}>
          <li className="navbar-item">
            <Link to="/" className="navbar-link">
              गृहपृष्ठ
            </Link>
          </li>
          <li className="navbar-item">
            <Link to="/about" className="navbar-link">
              मेरो बारेमा
            </Link>
          </li>
          <li className="navbar-item">
            <Link to="/gallery" className="navbar-link">
              फोटो ग्यालरी
            </Link>
          </li>
          <li className="navbar-item">
            <Link to="/contact" className="navbar-link">
              सम्पर्क
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;
